module.exports ={
  "propertyService": "https://nkjptmpey1.execute-api.us-east-1.amazonaws.com/shubham3",
  "domusoApiService": "https://f9uvdnfk8f.execute-api.us-east-1.amazonaws.com/shubham3",
  "appService": "https://domusograilsweb-shubham3.devmuso.com",
  "reservationService": "https://lf3cbxowvl.execute-api.us-east-1.amazonaws.com/shubham3",
  "userPoolId": "us-east-1_kPRU6HBdW",
  "authService": "https://ahgrxzvq04.execute-api.us-east-1.amazonaws.com/shubham3",
  "userPoolWebClientId": "22uckdipuae3ktona5v6ttgv9s",
  "emailLinkBase": "https://domusograilsweb-shubham3.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-dev@devmuso.com",
  "residentWebUI": "https://resident-shubham3.devmuso.com",
  "maintenanceUrl": "https://xy3jp7fzmc.execute-api.us-east-1.amazonaws.com/jon/maintenance",
  "env": "shubham3"
}