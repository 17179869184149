import React from "react";
import fieldsets from "./Components/AuthFormFieldsets";
import config from "./config";
import { Button } from "antd";
import CognitoFlowStates from "./Constants/CognitoFlowState";
import { CognitoUser } from "amazon-cognito-identity-js";
import { SetActiveFlows, SetBannerState, ShowSpinner } from "./State/actions";
import { formatCognitoPhoneNumber, trimWhiteSpaces } from "./utils";
import { nanoid } from "nanoid";
import "url-search-params-polyfill";
import { browserName, browserVersion, getUA, osName, osVersion } from "react-device-detect";

const isValidEmail = e => require("email-validator").validate(e);
const publicIp = require("public-ip");
const TEMP_PASSWORD = `1${nanoid()}Hh`;

const PASSWORD_REQUIREMENTS = `<aside role="region" class="narrow">
        <p class="requirements">Requirements</p>
      </aside>
     <ul class="header-content-list">
        <li>Password minimum is 8 characters</li>
        <li>Don’t use your name, email address, date of birth</li>
        <li>Make your password hard to guess, even for a friend</li>
     </ul>`;

const configurations = {
  resetPassword: {
    hidePropData: true,
    headerText: "Forgot Password?",
    buttonText: "Send Reset Link",
    onSuccess: () => {},
    subheaderContent:
      "Enter your Email Address or Username and we'll send you a link to reset your password.",
    surfaceErrors: new Map([
      [
        "InvalidParameterException",
        "If you have a Domuso account, please verify your email is entered correctly and try again. If you don't have an account, please create one."
      ], // unverified email
      [
        "UserNotFoundException",
        "If you have a Domuso account, please verify your email is entered correctly and try again. If you don't have an account, please create one."
      ], // user doesn't exist
      [
        "NotAuthorizedException",
        "If you have a Domuso account, please verify your email is entered correctly and try again. If you don't have an account, please create one."
      ] // user exists but doesn't have a password
    ]),
    nextFlow: CognitoFlowStates.PASSWORD_ACTION_REQUIRED,
    fields: props => {
      let FlowFieldSet = fieldsets["resetPassword"];
      return <FlowFieldSet {...props} />;
    },
    handleSubmit: async function(payload) {
      return sendResetPassword({ email: payload.email, cognitoContext: this });
    }
  },
  confirmAccount: {
    hidePropData: true,
    headerText: "Set Password",
    buttonText: "Set Password",
    nextFlow: CognitoFlowStates.SIGN_IN,
    onSuccess: "Your password has been updated.",
    surfaceErrors: new Map([
      [
        "ExpiredCodeException",
        "Link Expired. Create account, log in, or reset your password to continue."
      ]
    ]),
    subheaderContent: () => {
      return PASSWORD_REQUIREMENTS;
    },
    fields: props => {
      let FlowFieldSet = fieldsets["confirmAccount"];
      return <FlowFieldSet {...props} />;
    },
    handleSubmit: async function(payload) {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("t");
      const code = params.get("cvc");
      const flow = params.get("flow");
      const paypage = params.get("paypage");
      const pCode = params.get("pCode");
      const r = params.get("r");
      const u = params.get("u");
      const t = params.get("t");

      await validatePassword(t, payload.newPassword);
      if (flow === "ACU") {
        const user = await this.completeNewPassword(this.user, payload.newPassword);
        await completeAccountActivation(this, payload.attributes);
        if (paypage) {
          const finalPaypageUrl = `${config.payWithUrl}/property/${pCode}?r=${r}&u=${u}`;
          window.location.replace(finalPaypageUrl);
          return;
        }

        this.signOut();
        return user;
      }
      await this.forgotPasswordSubmit(token, code, payload.newPassword);
      await this.signIn(token, payload.newPassword);
      await completeAccountActivation(this, payload.attributes);
      this.signOut();

      async function completeAccountActivation(cognito, data) {
        let { username, attributes, signInUserSession } = cognito.user;
        if (!attributes) {
          attributes = { sub: signInUserSession.idToken.payload.sub };
        }
        try {
          let res = await fetch(`${config.reservationService}/completeSetup`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            body: JSON.stringify({
              username,
              cognito_id: attributes.sub,
              data
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: signInUserSession.idToken.jwtToken
            }
          });
          await sendUserFootprint(cognito.user);
          return { success: res.ok };
        } catch (e) {
          console.error("Failed to activate account", e);
          return { success: false };
        }
      }

      async function sendUserFootprint(user) {
        const { username, signInUserSession } = user;
        const ip_address = await publicIp.v4();
        const extras = {
          browserName,
          browserVersion,
          getUA,
          osName,
          osVersion,
          hostName: window.location.hostname
        };
        const body = {
          ip_address,
          extras,
          channel: "WEB",
          interaction_type: "SIGN_UP"
        };
        try {
          await fetch(`${config.domusoApiService}/v1/my/account/footprints`, {
            body: JSON.stringify(body),
            method: "PUT",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
              Authorization: signInUserSession.accessToken.jwtToken
            }
          });
        } catch (e) {
          console.error("Failed to post user footprints", { username, e });
        }
      }
    }
  },
  signIn: {
    buttonText: "Log In",
    headerText: "Log In",
    subheaderAlertContent:
      "Note: If entering Domuso for the first time, please create an account using the same email on file with your property.",
    subheaderContent: "Enter your Email Address and Password",
    surfaceErrors: new Map([
      [
        "UserNotFoundException",
        "If you have a Domuso account, please verify your email is entered correctly and try again. If you don't have an account, please create one."
      ],
      [
        "NotAuthorizedException",
        "If you have a Domuso account, please verify your email is entered correctly and try again. If you don't have an account, please create one."
      ],
      [
        "InvalidParameterException",
        "If you have a Domuso account, please verify your email is entered correctly and try again. If you don't have an account, please create one."
      ]
    ]),
    renderActions: (activeProperty, dispatch, history, formValid) => {
      const params = new URLSearchParams(window.location.search);
      const flow = params.get("flow");
      const isApplicantFlow = flow && flow === "applicant" ? true : false;

      return [
        <Button key="login" type="primary" htmlType="submit" disabled={!formValid}>
          Log In
        </Button>,
        <p key="or">OR</p>,
        <Button
          key=""
          type="default"
          className="default--white"
          htmlType="button"
          onClick={() => {
            if (isApplicantFlow) {
              const discountValue = params.get("discount");
              const discountParam = discountValue
                ? `discount=${discountValue}`
                : `noDiscount=${true}`;
              const uriPath = `/newAccount/create/${params.get(
                "propCode"
              )}?${discountParam}&flow=applicant`;
              window.location.href = config.appService + uriPath;
            } else {
              dispatch(SetActiveFlows([CognitoFlowStates.SIGN_UP, history]));
            }
          }}
        >
          Create Account
        </Button>
      ];
    },
    fields: props => {
      let FlowFieldSet = fieldsets["signIn"];
      return <FlowFieldSet {...props} />;
    },
    handleSubmit: async function({ email, password }) {
      let addlConfigs = {};
      let aliased = false;
      if (!email.includes("@")) {
        aliased = true;
        addlConfigs = { clientMetadata: { preferred_username: email } };
      }
      this.Auth = this.reconfigure({
        authenticationFlowType: "USER_PASSWORD_AUTH",
        ...addlConfigs
      });
      let authData = await this.signIn({ username: email, password });
      if (authData.signInUserSession) {
        let token = `Bearer ${authData.signInUserSession.idToken.jwtToken}`;
        // uri to redirect for test/prod environments
        let uriToRedirect = config.emailLinkBase;
        let uriPath = "";
        const env = process.env.REACT_APP_ENV;
        const isDev = !["test", "prd", "production"].includes(env);
        const params = new URLSearchParams(window.location.search);
        const flow = params.get("flow");
        if (flow && flow === "applicant") {
          const discountValue = params.get("discount");
          const discountParam = discountValue ? `discount=${discountValue}` : `noDiscount=${true}`;

          uriPath = `/reserve/myReservationLogin/${params.get(
            "propCode"
          )}?${discountParam}&flow=applicantLogsIn`;
        }

        if (isDev) {
          const redirectUri = params && params.get("redirect_uri");
          if (redirectUri && /^http:\/\/localhost(:\d+)*\//.test(decodeURIComponent(redirectUri))) {
            // redirect to "redirect_uri" value if not test or production env
            // and the value of the redirect URL starts with "http://localhost/"
            uriToRedirect = redirectUri;
          } else {
            uriToRedirect = config.appService;
          }
        }

        let hasBlockedReservation = false;
        const accessToken = authData.signInUserSession.accessToken.jwtToken;
        const idToken = authData.signInUserSession.idToken.jwtToken;
        const refreshToken = authData.signInUserSession.refreshToken.token;
        const userRoles = await getUserRoles(authData);
        // Check if the user is a resident, we should skip checking for blocked reservations for the managers.
        const isResident = userRoles.map(({ user_role }) => user_role).includes("ROLE_CUSTOMER");
        // In case of resident and having blocked reservations, we need to redirect to react web.
        if (isResident) {
          const reservationData = await getReservationData(authData);
          hasBlockedReservation = hasAnyBlockedReservationForRecovery(reservationData);
        } else {
          console.info("No need to check for blocked reservation for non-resident users");
        }

        if (hasBlockedReservation) {
          uriToRedirect = config.residentWebUI;
          const decodedUrl = decodeURIComponent(uriToRedirect);
          try {
            await fetch(decodedUrl, {
              method: "GET",
              cache: "no-cache",
              credentials: "same-origin"
            }).then(response => {
              // NOTE: Redirect to react app for DCash recovery, to prevent users from logging
              // for the second time.
              let domain = window.location.hostname;
              // Hack for cases like https://auth-ardalan4.devmuso.com, we only need devmuso.com portion.
              domain = domain.split(".");
              domain = domain.slice(-2)[0] + "." + domain.slice(-1)[0];
              // To access cookies in all sub-domains need to set domain=
              document.cookie = `WEBAPP_ACCESS_TOKEN=${accessToken}; domain=${domain};`;
              document.cookie = `WEBAPP_ID_TOKEN=${idToken}; domain=${domain};`;
              document.cookie = `WEBAPP_REFRESH_TOKEN=${refreshToken}; domain=${domain};`;
              let url = response.url;
              window.location.href = url;
            });
          } catch (e) {
            console.error("Failed to authenticate user.", { email, e });
          }
        }

        const decodedUrl = decodeURIComponent(uriToRedirect + uriPath);
        try {
          await fetch(decodedUrl, {
            method: "GET",
            credentials: "include",
            headers: {
              Authorization: token
            }
          }).then(response => {
            let url = response.url;
            window.location.href = url;
          });
        } catch (e) {
          console.error("Failed to authenticate user.", { email, e });
        }
      }

      async function getUserRoles(authData) {
        try {
          const response = await fetch(`${config.domusoApiService}/v1/my/account/user_roles`, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
              Authorization: authData.signInUserSession.accessToken.jwtToken
            }
          });
          const userRoleData = await response.json();
          console.info(`Got User Roles`, userRoleData);
          return userRoleData.user_roles;
        } catch (e) {
          console.error("Failed to get user role details", { e });
          return {};
        }
      }

      async function getReservationData(authData) {
        // Get user reservation data to decide on redirection if DCash Recovery needed.
        try {
          const response = await fetch(
            `${config.domusoApiService}/v1/my/reservation?omit_balance_due=true`,
            {
              method: "GET",
              mode: "cors",
              cache: "no-cache",
              credentials: "same-origin",
              headers: {
                "Content-Type": "application/json",
                Authorization: authData.signInUserSession.accessToken.jwtToken
              }
            }
          );
          const reservationData = await response.json();
          console.info(`Found ${reservationData.length} reservation for the user`);
          return reservationData;
        } catch (e) {
          console.error("Failed to get reservation details", { e });
          return {};
        }
      }

      function hasAnyBlockedReservationForRecovery(reservationData) {
        const anyBlockedReservation = reservationData.find(reservation => {
          if (reservation.recovery_payments.length > 0) {
            console.info(
              `Found blocked reservation that require recovery payments --> Id: ${reservation.id}`
            );
            return true;
          }
          return false;
        });
        return anyBlockedReservation;
      }

      return authData;
    }
  },
  register: {
    headerText: "Create Account",
    nextFlow: "register/actionRequired",
    onSuccess: ({ email }) =>
      isValidEmail(email) ? `Confirmation email sent to ${email}.` : `Confirmation email sent.`,
    fields: props => {
      let FlowFieldSet = fieldsets["register"];
      return <FlowFieldSet {...props} />;
    },
    renderActions: (activeProperty, dispatch, history, formValid, state) => {
      return (
        <div>
          <Button
            type="primary"
            htmlType="submit"
            loading={state.fetching}
            disabled={state.fetching || !formValid}
          >
            Next: Confirm Email
            <i
              className="material-icons mdc-text-field__icon mdc-text-field__icon--leading"
              role="button"
            >
              arrow_right_alt
            </i>
          </Button>
          <div style={{ marginTop: "15px" }}>
            <div style={{ color: "black", fontSize: "15px" }}>
              {"Already have an account? "}
              <a href={`/singIn`} style={{ color: "black", fontSize: "15px" }}>
                <u>{"Log In"}</u>
              </a>
            </div>
          </div>
        </div>
      );
    },
    handleSubmit: async function(payload, dispatch, form) {
      let { username, password = TEMP_PASSWORD, clientMetadata, ...attributes } = payload;
      let registrationParams = {
        username,
        password,
        clientMetadata,
        attributes: {
          ...attributes,
          phone_number: formatCognitoPhoneNumber(attributes.phone_number)
        }
      };
      this.Auth = this.reconfigure({
        clientMetadata,
        authenticationFlowType: "USER_PASSWORD_AUTH"
      });
      const handleExistingUserErr = async e => {
        if (e.name === "UsernameExistsException") {
          let user = new CognitoUser({
            Username: username,
            Pool: this.userPool
          });
          user = await this.signIn(user, "BAD_PASSWORD")
            .catch(e => {
              if (e.name !== "UserNotFoundException") throw e;
            })
            .then(() => this.signUp(registrationParams))
            .then(() => this.signIn(username, TEMP_PASSWORD));
          return user;
        }
        throw e;
      };
      return this.signUp(registrationParams)
        .then(() => this.signIn(username, TEMP_PASSWORD))
        .catch(handleExistingUserErr)
        .then(() => this.signOut())
        .catch(e => {
          console.error("Sign up failed", e);
          if (!e.message.includes("Attempted to confirm already confirmed account")) throw e;
          form.setFields({
            email: {
              value: form.values && form.values.email,
              errors: [
                {
                  message: (
                    <span>
                      An account with the given email already exists. Please verify the email
                      address was entered correctly and log in, or try using a different email.{" "}
                      <a
                        onClick={() => {
                          dispatch(SetActiveFlows("resetPassword"));
                          form.setFields({
                            email: { value: form.values && form.values.email }
                          });
                        }}
                      >
                        Reset password instead
                      </a>
                      ?
                    </span>
                  )
                }
              ]
            }
          });
          e.name = "SilentFailure";
          throw e;
        });
    }
  },
  accountActionRequired: {
    hidePropData: true,
    headerText: "Confirm Email",
    fields: props => {
      let FlowFieldSet = fieldsets["accountActionRequired"];
      return <FlowFieldSet {...props} />;
    },
    renderActions: (activeProperty, dispatch, history, formValid, state) => {
      return (
        <div>
          <summary className={"action-required button-hint"}>
            Don’t see the email? Make sure to check your Spam Folder.
          </summary>
          <Button
            type="default"
            htmlType="submit"
            loading={state.fetching}
            disabled={state.fetching}
          >
            Resend Email
          </Button>
          <summary className={"action-required link"}>
            <a href={`https://www.domuso.com/`}>Return to Domuso Home Page</a>
          </summary>
        </div>
      );
    },
    onSuccess: ({ email }) =>
      isValidEmail(email)
        ? `Confirmation email re-sent to ${email}.`
        : `Confirmation email re-sent.`,
    handleSubmit: async function(payload, dispatch) {
      dispatch(SetBannerState({ show: false }));
      dispatch(ShowSpinner(true));
      const username = payload.email.trim();
      try {
        return await this.resendSignUp(username);
      } catch (error) {
        return resendInviteOrForgotPassword(username, false, error);
      }
    }
  },
  "resetPassword/actionRequired": {
    hidePropData: true,
    headerText: "Confirm email",
    subheaderContent: state => {
      const cognito = state.cognito;
      let email = cognito ? cognito.email : "";
      let sentText = email
        ? isValidEmail(email)
          ? `sent to <span class="subheader-email">${email}</span>`
          : "sent"
        : "sent";

      return `A Confirmation Email has been ${sentText}. Click the link in the email to verify your email address and set your password.`;
    },
    handleSubmit: async function(payload) {
      return sendResetPassword({ email: payload.email, cognitoContext: this });
    },
    renderActions: (activeProperty, dispatch, history, formIsValid, state) => {
      const actions = [
        <summary className={"action-required button-hint"}>
          Don’t see the email? Make sure to check your Spam Folder.
        </summary>
      ];

      const email = state.cognito && state.cognito.email;
      if (email) {
        actions.push(
          <Button
            key=""
            type="default"
            className="default--white"
            htmlType="submit"
            loading={state.fetching}
            disabled={state.fetching}
          >
            Resend Email
          </Button>
        );
      }

      actions.push(
        <a className={`action-link`} href={`https://www.domuso.com`}>
          Return to Domuso Home Page
        </a>
      );

      return actions;
    }
  },
  newPassword: {
    hidePropData: true,
    headerText: "Set Password",
    buttonText: "Set Password",
    onSuccess: "Your password has been updated.",
    nextFlow: CognitoFlowStates.SIGN_IN,
    surfaceErrors: new Map([
      [
        "ExpiredCodeException",
        "Link Expired. Create account, log in, or reset your password to continue."
      ]
    ]),
    subheaderContent: () => {
      return PASSWORD_REQUIREMENTS;
    },
    fields: props => {
      let FlowFieldSet = fieldsets["newPassword"];
      return <FlowFieldSet {...props} />;
    },
    handleSubmit: async function(payload) {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("t");
      const code = params.get("cvc");
      const t = params.get("t");
      await validatePassword(t, payload.newPassword);
      return this.forgotPasswordSubmit(token, code, trimWhiteSpaces(payload.newPassword));
    }
  },
  keys: {
    confirmaccount: "confirmAccount",
    resetpassword: "resetPassword",
    verifyconfirmationcode: "verify",
    newpassword: "newPassword",
    signin: "signIn",
    signup: "register",
    "register/actionRequired": "accountActionRequired",
    "resetPassword/actionRequired": "resetPassword/actionRequired"
  }
};

const sendResetPassword = async function(payload) {
  const username = payload.email.trim();
  return await resendInviteOrForgotPassword(username, true);
};

const validatePassword = async (username, password) => {
  const uri = `${config.authService}/validatePassword?`;
  const response = await fetch(uri, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ username, password }),
    headers: {
      "Content-Type": "application/json"
    }
  });
  if (response.status === 200) {
    const { validated } = await response.json();
    if (!validated) {
      throw new Error(
        "This password is too easy to guess. Please refer to the Requirements and try again."
      );
    }
  } else {
    throw new Error("Failed to validate password. Please try again");
  }
};

const resendInviteOrForgotPassword = async (username, forgotPassword, error = null) => {
  if (error != null && error.code !== "NotAuthorizedException") {
    throw error;
  }

  const response = await fetch(`${config.authService}/resendInvite`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ username, forgotPassword }),
    headers: {
      "Content-Type": "application/json"
    }
  });
  if (response.status >= 400 && response.status < 600) {
    const finalErr = new Error(
      error
        ? error.message || ""
        : "If you have a Domuso account, please verify your email is entered correctly and try again. If you don’t have an account, please create one"
    );
    finalErr.code = "NotAuthorizedException";
    throw finalErr;
  }
  return response;
};

export default configurations;
